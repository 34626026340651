/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import Sns from "./sns";
import Links from "./links";

const Footer = () => {
    return (
        <div style={{
            background: "#fff"
        }}>
            <div sx={{
                position: "relative",
                margin: "0 auto",
                zIndex: 1,
                alignSelf: "center",
                maxWidth: "960px",
                padding: `0 1.0875rem 0`,
            }}>
                <div sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    "@media (max-width: 901px)": {
                        justifyContent: "space-between",
                    }
                }}>
                    <Sns />
                    <div style={{marginLeft: 35}}>
                        <Links />
                    </div>
                </div>

                <p style={{
                    textAlign: "center",
                    fontSize: 12,
                    marginTop: 10,
                    paddingTop: 21,
                    paddingBottom: 20,
                    borderTop: "1px solid #C6C6C6",
                    color: "#42210B",
                }}>
                    © {new Date().getFullYear()}, Off-On, All Rights Reserved.
                </p>
            </div>
        </div>
    )
}

export default Footer
