/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import PropTypes from "prop-types"
import {graphql, Link, useStaticQuery} from "gatsby";
import offonLogo from "../../images/offon-logo.png";

const Logo = ({siteTitle, isSticky}) => {
    return (
        <h1 sx={{
            textAlign: "center",
            "@media (max-width: 1085px)": {
                textAlign: "left",
                paddingLeft: 30
            }
        }}>
            <Link to="/" sx={{
                display: "inline-block",
                "img": {
                    transition: "all 200ms ease-in",
                    width: isSticky? 123 : 223
                }
            }}>
                <img src={offonLogo}  alt={siteTitle} style={{verticalAlign: "bottom"}} />
            </Link>
        </h1>
    )
}

Logo.Logo = {
    siteTitle: PropTypes.string.isRequired,
    isSticky: PropTypes.bool,
}

export default Logo
