/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import NavbarLink from "./navbar-link";
import PropTypes from "prop-types";

const NavbarLinks = ({open, isTop, onClick}) => {
    return (
        <div sx={{
            position: "absolute",
            top: 0,
            right: 30,
            display: "flex",
            height: "100%",
            justifyContent: "flex-end",
            alignItems: "center",
            "> span": {
                color: "#fff",
                margin: "0 5px"
            },
            "@media (max-width: 901px)": {
                paddingTop: 45,
                flexDirection: "column",
                position: "fixed",
                width: "100%",
                justifyContent: "flex-start",
                backgroundColor: "#fff",
                transition: "all 0.3s ease-in",
                top: 0,
                left: open? "0" : "-100%",
                "> span": {
                    display: "none"
                },
            }
        }}>
            <NavbarLink to={!isTop? "/#concept": ""} scroll={"#concept"}  onClick={onClick}>Concept</NavbarLink><span>|</span>
            <NavbarLink to={!isTop? "/#creator": ""} scroll={"#creator"}  onClick={onClick}>Creator</NavbarLink><span>|</span>
            <NavbarLink to={!isTop? "/#order": ""} scroll={"#order"}  onClick={onClick}>Order</NavbarLink><span>|</span>
            <NavbarLink to={!isTop? "/#company": ""} scroll={"#company"}  onClick={onClick}>Company</NavbarLink><span>|</span>
            <NavbarLink to={"/contact"} onClick={onClick}>Contact</NavbarLink>
        </div>
    )
}

NavbarLinks.propTypes = {
    open: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    isTop: PropTypes.bool,
}

export default NavbarLinks