/** @jsx jsx */
import { jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import NavbarLinks from "./navbar-links"
import NavbarHamburger from "./navbar-hamburger";
import Logo from "./logo";
import { throttle } from 'lodash';

const Header = ({siteTitle, isTop}) => {
    const [navbarOpen, setNavbarOpen] = useState(false)

    const [isSticky, setSticky] = useState(false);

    const handleScroll = () => {
        const { scrollTop: currentScrollTop } = document.documentElement || document.body;
        setSticky(currentScrollTop >= 20);
    };

    const handleScrollThrottled = throttle(handleScroll, 50);

    useEffect(() => {
        window.addEventListener('scroll', handleScrollThrottled);

        return () => {
            window.removeEventListener('scroll', () => handleScrollThrottled);
        };
    }, []);

    const toggleNavbarOpen = () => {
        setNavbarOpen(!navbarOpen)
    };

    return (
        <div sx={{
            position: "fixed",
            width: "100%",
            top: 0,
            left: 0,
            zIndex: 2,
            backgroundColor: "primary",
        }}>
            <div sx={{
                position: "relative",
                margin: "0 auto",
                zIndex: 2,
                alignSelf: "center",
                maxWidth: "1280px"
            }}>
                <Logo siteTitle={siteTitle} isSticky={isSticky} />

                <NavbarHamburger open={navbarOpen} onClick={toggleNavbarOpen} />

                <NavbarLinks open={navbarOpen} isTop={isTop || false} onClick={toggleNavbarOpen} />
            </div>
        </div>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
    isTop: PropTypes.bool,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
